import React from "react"
import Nav from "../components/nav"
import Footer from "../components/footer"

const Datenschutz = () => {
  return (
    <div>
      <Nav />
      <div className="grid-container">
        <h2>Datenschutz</h2>
        <div className="gap--l">
          <h3>1. Datenschutzerklärung</h3>
          <p>
            Wir freuen uns über Ihren Besuch auf unserer Webseite. Nachfolgend möchten wir Sie über den Umgang mit Ihren
            Daten gem. Art. 13 Datenschutzgrundverordnung (DSGVO) informieren.
          </p>
          <h4>Verantwortlicher</h4>
          <p>
            Verantwortlich für die nachfolgend dargestellte Datenerhebung und Verarbeitung ist die im Impressum genannte
            Stelle.{" "}
          </p>
          <h4>Nutzungsdaten</h4>
          <p>
            Wenn Sie unsere Webseiten besuchen, werden auf unserem Webserver temporär sogenannte Nutzungsdaten zu
            statistischen Zwecken als Protokoll gespeichert, um die Qualität unserer Webseiten zu verbessern. Dieser
            Daten-satz besteht aus
          </p>
          <ul>
            <li>der Seite, von der aus die Datei angefordert wurde,</li>
            <li>dem Namen der Datei,</li>
            <li>dem Datum und der Uhrzeit der Abfrage,</li>
            <li>der übertragenen Datenmenge,</li>
            <li>dem Zugriffsstatus (Datei übertragen, Datei nicht gefunden),</li>
            <li>der Beschreibung des Typs des verwendeten Webbrowsers,</li>
            <li>
              der IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein Personenbezug nicht mehr
              herstellbar ist.{" "}
            </li>
          </ul>
          <p>Die genannten Protokolldaten werden nur anonymisiert gespeichert.</p>
        </div>
        <div className="gap--l">
          <h3>2. Cookies</h3>
          <p>
            Auf unserer Webseite nutzen wir Cookies. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert
            und ausgelesen werden können. Man unterscheidet zwischen Session-Cookies, die wieder gelöscht werden, sobald
            Sie ihren Browser schließen und permanenten Cookies, die über die einzelne Sitzung hinaus gespeichert
            werden. Cookies können Daten enthalten, die eine Wiedererkennung des genutzten Geräts möglich machen.
            Teilweise enthalten Cookies aber auch lediglich Informationen zu bestimmten Einstellungen, die nicht
            personenbeziehbar sind.{" "}
          </p>
          <p>
            Wir nutzen auf unserer Webseite Session-Cookies und permanente Cookies. Die Verarbeitung erfolgt auf
            Grund-lage von Art. 6 Abs. 1 lit. f DSGVO und in dem Interesse die Benutzerführung zu optimieren bzw. zu
            ermöglichen und die Darstellung unserer Webseite anzupassen.
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass er Sie über die Platzierung von Cookies informiert. So wird der
            Ge-brauch von Cookies für Sie transparent. Sie können Cookies zudem jederzeit über die entsprechende
            Browserein-stellung löschen und das Setzen neuer Cookies verhindern. Bitte beachten Sie, dass unsere
            Webseiten dann ggf. nicht optimal angezeigt werden und einige Funktionen technisch nicht mehr zur Verfügung
            stehen.
          </p>
        </div>
        <div className="gap--l">
          <h3>3. Datenübermittlung an Dritte</h3>
          <h4>Mailjet</h4>
          <p>
            Wenn Sie sich für den helpyourlocalstore Newsletter eintragen, wird Ihre E-Mail an Mailjet weitergeleitet.
            Um Spam zu vermeiden werden sie erst E-Mails von uns bekommen, wenn sie per E-Mail ihre Einwilligung gegeben
            haben. Sie haben jederzeit die Möglichkeit sich wieder von dem Newsletter abzumelden.
          </p>
          <a
            href="https://www.mailjet.de/privacy-policy/?gclid=Cj0KCQjws_r0BRCwARIsAMxfDRidVQsK8uXmbjVsUlPEHkV-37GaSTRiQzmsTWuv1e3AmQT2eG5meocaAullEALw_wcB"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Datenschutzerklärung von Mailjet
          </a>
        </div>
        <div className="gap--l">
          <h3>4. Trackingtools</h3>
          <h4>Google Analytics</h4>
          <p>
            Unsere Internetseiten benutzen auf der Grundlage von Art. 6 Abs. 1 lit. f) DSGVO GoogleAnalytics, einen
            Webanalysedienst der Google Inc., 600 Amphitheatre Parkway Mountain View, Ca 94043, USA („Google“) zum
            Zwecke der bedarfsgerechten Gestaltung und Optimierung unseres Internetauftrittes.
          </p>
          <p>
            GoogleAnalytics verwendet Cookies und erstellt pseudonymisierte Nutzungsprofile. Die durch den Cookie
            erzeugten Informationen über Ihre Benutzung unserer Internetseiten (Browsertyp und -version; Betriebssystem,
            Referrer-URL, IP-Adresse und Uhrzeit der Serveranfrage) werden in der Regel an einen Server von Google in
            den USA übertragen und dort gespeichert. Ihre IP-Adresse wird von Google zuvor innerhalb der
            Mitgliedsstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen
            Wirtschaftsraum gekürzt und so anonymisiert, dass eine Zuordnung zu Ihrer Person nicht möglich ist
            (IP-Masking). Eine Zusammenführung der IP-Adresse mit anderen Daten von Google erfolgt nicht. In unserem
            Auftrag gemäß Art. 28 DSGVO wird Google die erhobenen Informationen verwenden, um Ihre Nutzung unserer
            Internetseiten auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
            Nutzung unserer Internetseiten und des Internets verbundene Dienstleistungen zu erbringen.
          </p>
          <p>
            Sie können die Speicherung von Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
            verhindern; wir weisen jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen
            unseres Internetauftritts vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der
            durch das Cookie erzeugten und auf Ihre Nutzung unserer Internetseiten bezogenen Daten (inkl. Ihrer
            IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem
            folgenden Link verfügbare Browser-Add-on herunterladen und installieren:{" "}
            <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">
              http://tools.google.com/dlpage/gaoptout?hl=de
            </a>
          </p>
          <p>
            Weitere Informationen zu Nutzungsbedingungen und Datenschutz im Zusammenhang mit GoogleAnalytics finden Sie
            auf den{" "}
            <a href="https://marketingplatform.google.com/about/" target="_blank" rel="noopener noreferrer">
              {" "}
              Internetseiten von Google
            </a>
            .
          </p>
        </div>
        <div className="gap--l">
          <h3>5. Social Media Plugins</h3>
          <p>
            Aus Gründen des Datenschutzes binden wir keine Social Plugins direkt in unseren Webauftritt ein. Wenn Sie
            unsere Seiten aufrufen, werden daher keine Daten an Social Media-Dienste, wie etwa Facebook, Twitter, XING
            o-der Google+ übermittelt. Eine Profilbildung durch Dritte ist somit ausgeschlossen.
          </p>
        </div>
        <div className="gap--l">
          <h3>6. Erläuterungen der Sicherheitsmaßnahmen</h3>
          <p>
            Um Ihre Daten vor unerwünschten Zugriffen möglichst umfassend zu schützen, treffen wir technische und
            organisatorische Maßnahmen. Wir setzen auf unseren Seiten ein Verschlüsselungsverfahren ein. Ihre Angaben
            werden von Ihrem Rechner zu unserem Server und umgekehrt über das Internet mittels einer TLS-Verschlüsselung
            übertragen. Sie erkennen dies daran, dass in der Statusleiste Ihres Browsers das Schloss-Symbol geschlossen
            ist und die Adresszeile mit https:// beginnt.{" "}
          </p>
        </div>
        <div className="gap--l">
          <h3>7. Standort</h3>
          <p>
            Bei Einwilligung des Standorts wird Ihr aktueller Standort gespeichert. Dies dient dazu, Ihnen ein besseres
            Nutzungserlebnis bieten zu können. Wir haben keinen Zugriff auf diese Daten und werden sie somit auch nicht
            verwenden{" "}
          </p>
        </div>
        <div className="gap--l">
          <h3>8. Rechte des Nutzers</h3>
          <h4>Ihre Rechte als Nutzer</h4>
          <p>
            Bei Verarbeitung Ihrer personenbezogenen Daten gewährt die DSGVO Ihnen als Webseitennutzer bestimmte Rechte:
          </p>
          <h4>1. Auskunftsrecht (Art. 15 DSGVO): </h4>
          <p>
            Sie haben das Recht eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten
            verarbei-tet werden; ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen
            Daten und auf die in Art. 15 DSGVO im einzelnen aufgeführten Informationen.{" "}
          </p>
          <h4>2. Recht auf Berichtigung und Löschung (Art. 16 und 17 DSGVO): </h4>
          <p>
            Sie haben das Recht, unverzüglich die Berichtigung sie betreffender unrichtiger personenbezogener Daten und
            ggf. die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.{" "}
          </p>
          <p>
            Sie haben zudem das Recht, zu verlangen, dass sie betreffende personenbezogene Daten unverzüglich gelöscht
            werden, sofern einer der in Art. 17 DSGVO im einzelnen aufgeführten Gründe zutrifft, z. B. wenn die Daten
            für die verfolgten Zwecke nicht mehr benötigt werden.{" "}
          </p>
          <h4>3. Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO): </h4>
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO
            aufgeführ-ten Voraussetzungen gegeben ist, z. B. wenn Sie Widerspruch gegen die Verarbeitung eingelegt
            haben, für die Dau-er einer etwaigen Prüfung.{" "}
          </p>
          <h4>4. Recht auf Datenübertragbarkeit (Art. 20 DSGVO): </h4>
          <p>
            In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt werden, haben Sie das Recht, die sie
            betref-fenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu
            erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.{" "}
          </p>
          <h4>5. Widerspruchsrecht (Art. 21 DSGVO): </h4>
          <p>
            Werden Daten auf Grundlage von Art. 6 Abs. 1 lit. f erhoben (Datenverarbeitung zur Wahrung berechtigter
            Interes-sen), steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
            jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann
            nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die
            die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </p>
          <h4>6. Beschwerderecht bei einer Aufsichtsbehörde</h4>
          <p>
            Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind,
            dass die Verarbeitung der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das
            Be-schwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres Aufenthaltsorts,
            Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Datenschutz
